
import globalEvents from '~/mixins/global-events';

export default {
  name: 'section-affiliator',
  mixins: [globalEvents],
  props: {
    isLoaded: Boolean,
  },
  data () {
    return {
      isShowAffiliator: false,
    };
  },
  mounted() {
    this.$nuxt.$on('show-aff', this.showAffiliator());
  },
  beforeDestoy() {
    this.$bus.$off('show-aff');
  },
  methods: {
    showAffiliator() {
      this.isShowAffiliator = true;
    },
  },
};
