
import {mapGetters} from 'vuex';

export default {
  name: 'base-logo',
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    withoutText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      codes: {
        'FRU': 'manas',
        'OSS': 'osh',
        'IKU': 'issukul',
      },
    };
  },
  computed: {
    ...mapGetters('sulu/airport', ['about']),
    city() {
      return this.about ? this.codes[this.about?.code] : 'manas';
    },
  },
};
